/* Module: S */
.slide_in_menu {
    text-align: center;
    background-color: $white;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 3;
    left:calc(-100%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .menutrigger {
        position: relative;
        font-size: pxToRem(20);
        padding: 12px 15px 5px 15px;
        margin:0;
        display: block;
        width:100%;
        font-weight: $regular;
        &:after {
            display: block;
            content: " ";
            position: absolute;
            right: 20px;
            top:24px;
            width: 10px;
            height: 10px;
            background: url({{file_dest}}/closetrigger.svg) center center/contain no-repeat;
        }
    }
    .betaversion {
        padding: 0px 20px;
        margin-top: 50px;
        text-align: left;
    }
}

.slide_in_menu_open {
    left: 0px;
}
.slide_in_menu_list {
    list-style: none;
    margin: 0px;
    padding: 0px 20px;
    width: 100%;
}

.slide_in_menu_list--element {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0px;
    border-bottom: 2px solid $gray-darker;
    a {
        text-decoration: none;
        color: $base-font-colour;
        font-weight: $regular;

    }
}