/* Module: S */
.learning_list {
    transition: all 0.5s ease-in-out;
    padding: 10px 10px 80px 10px;
    .featured_download {
        position: relative;
        font-size: pxToRem(14);
        line-height: pxToRem(22);
        display: block;
        background-color: $white;
        margin: 10px 15px 20px 15px;
        @media screen and (min-width: 321px){
            margin: 10px 40px 20px 40px;
        }
        padding: 10px 10px 10px 70px;
        color: $base-font-colour;
        text-decoration: none;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        &:before {
            position: absolute;
            display: block;
            content: "";
            width: 40px;
            height: 40px;
            background: url({{file_dest}}/download.svg) center center no-repeat;
            top:calc(50% - 20px);
            left:15px;
        }
    }
    .articles_list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .articles_list--element {
        clear: both;
        background-color: $white;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        height: 100px;
        overflow: hidden;
        margin-bottom: 10px;
        .img_wrapper {
            background: url({{file_dest}}/img_wrapper.png) center center/cover no-repeat;
            position: relative;
            padding-bottom: 100px;
            height: 0;
            overflow: hidden;
            margin-bottom: 0;
            width: 100px;
            float: left;
            .image {
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
            }
        }
        .content_wrapper {
            position: relative;
            float: left;
            width: calc(100% - 100px);
            height: 100px;
            padding: 8px 10px;
            h3 {
                color: black;
                margin: 2px 0px;
                font-size: pxToRem(18);
                font-weight: $regular;
            }
            .details {
                margin: 0px;
                font-size: pxToRem(12);
                color: $gray-darkest;
                position: absolute;
                bottom: 5px;
                left: 10px;
                line-height: 20px;
            }

        }
    }
}