/* Set font path for bootstrap */
$icon-font-path: "mysource_files/";

/* Gets bootstrap variables for media size */
//@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 14; // Used for font size calculations & conversions
$base-font-colour: #4a4a4a;
$semibold: 600;
$medium: 500;
$regular: 400;
$light: 300;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

//basic colours
$white: #ffffff;

//brand colours
$blue: #006EB6;
$button-blue: #4197f8;
$gray-button: #f1f3f2;
$gray-darker: #d6d6d6;
$gray-darkest: #575656;
$green: #81bb26;
$yellow: #f9b122;
$accessible-grey: #313131;