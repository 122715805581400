//Buttons

.button {
    background-color: $blue;
    @include sq-border-radius(5px);
    padding: 0.3em 2em;
    color: $white;
    margin-bottom: 15px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    &:focus,
    &:hover {
        color: $white;
        background-color: $blue;
    }
}

.reader_only {
    display: none;
}