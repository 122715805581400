/* Module: S */
.login {
    width: 100%;
    text-align: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    .button {
        color: $base-font-colour;
        background-color: $yellow;
        border: 1px solid $yellow;
        font-size: pxToRem(16);
        font-weight: 400;
        padding: 0.6em 2.6em;

        position: absolute;
        bottom: 125px;
        left: calc(50% - 63px);

        &:focus,
        &:hover {
            //color: $white;
            text-decoration: none;
            background-color: white;
        }
    }

}
.formatterwrapper {
    background: url({{file_dest}}/login_background.png) center center/cover no-repeat;
    background-attachment: fixed;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right:0;
}
.logo{
    background: url({{file_dest}}/ICAS_Master_Logo_L.png) center center/contain no-repeat;
    display: block;
    width: 100%;
    height: 57px;
    margin: 130px 0 20px 0 ;
}
.calogo{
    background: url({{file_dest}}/calogo.png) center center/contain no-repeat;
    display: block;
    width: 100%;
    height: 37px;
    margin: 80px 0;
}
.betaversion {
    padding: 0 40px;
    margin-bottom: 50px;
}