/* Module: S */
.event_list {
    transition: all 0.5s ease-in-out;
    padding: 10px 10px 80px 10px;
    .articles_list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .articles_list--element {
        clear: both;
        background-color: $white;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        height: 100px;
        overflow: hidden;
        margin-bottom: 10px;
        .img_wrapper {
            background: url({{file_dest}}/img_wrapper.png) center center/cover no-repeat;
            position: relative;
            padding-bottom: 100px;
            height: 0;
            overflow: hidden;
            margin-bottom: 0;
            width: 100px;
            float: left;
            .image {
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
            }
        }
        .content_wrapper {
            position: relative;
            float: left;
            width: calc(100% - 100px);
            height: 100px;
            padding: 8px 10px;
            h3 {
                color: black;
                margin: 2px 0px;
                font-size: pxToRem(18);
                font-weight: $regular;
            }
            .details {
                margin: 0px;
                font-size: pxToRem(10);
                color: $gray-darkest;
                position: absolute;
                bottom: 5px;
                left: 10px;
                line-height: 14px;
            }

        }
    }
    .articles_list--element-featured {
        @extend .articles_list--element;
        background-color: black;
        height: auto;
        .img_wrapper {
            padding-bottom: 146px;
            width: 100%;
            .image {
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
            }
        }
        .content_wrapper {
            padding: 8px 10px 3px 10px;
            color: $white;
            width: 100%;
            height: 85px;
            h3 {
                color: $white;
            }
            .details {
                color: $white;
            }
        }
    }
}