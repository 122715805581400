/* Module: S */
.topics {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    transition: all 0.5s ease-in-out;
    h1 {
        font-weight: 300;
        font-size: pxToEm(30);
    }
    p {
        text-align: left;
        font-weight: 400;
        padding: 45px 20px;
    }

    .topics-list {
        list-style: none;
        margin: 0;
        text-align: left;
        padding: 0 10px 55px 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }
    .topics-list__item {
        padding: 2px;
        @media screen and (min-width: 321px){
            padding: 2px 15px;
        }
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin: 10px 5px;
        background-color: $gray-button;
        border-radius: 5px;
        min-height: 48px;
        height: 48px;
        width: calc(50% - 10px);
        align-self: center;
        line-height: pxToRem(20);
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    }
    .active {
        background-color: $yellow;
    }
}
.nextButton {
    position: fixed;
    bottom: 0px;
    left:0px;
    right:0px;
    height: 44px;
    background-color: rgba(255,255,255,0.8);
    padding: 0 37px;
    a {
        color: black;
        text-decoration: none;
        font-size: pxToRem(20);
        font-weight: $medium;
        margin: 0;
        line-height: pxToRem(45);
    }
}