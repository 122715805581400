/* Module: S */
header {
    width: 100%;
    display: table;
    background-color: rgba(255,255,255,0.85);
    .gray & {
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.2);
    }
    .toplogo_centered {
        display: table;
        font-size: pxToRem(30);
        font-weight: 600;
        height: 39px;
        text-align: center;
        margin: auto;
        padding-top: 16px;
        .toplogoimage {
            width: 100px;
            height: 47px;
            background: url({{file_dest}}/calogo.png) center center/contain no-repeat;
        }
    }
    .toplogo {
        display: table;
        font-size: pxToRem(30);
        font-weight: 600;
        height: 39px;
        text-align: center;
        margin: auto;
        padding: 4px 15px 0 0 ;
        .toplogoimage {
            width: 100px;
            height: 47px;
            background: url({{file_dest}}/calogo.png) center center/contain no-repeat;
        }
    }
    .menutrigger {
        font-size: pxToRem(20);
        padding: 12px 5px 5px 15px;
    }
}