/* Module: S */
.connect_list {
    transition: all 0.5s ease-in-out;
    padding: 10px 10px 80px 10px;
    .articles_list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .articles_list--element {
        display: flex;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        height: 100px;
        overflow: hidden;
        margin: 10px 15px;
        padding: 5px 5px 5px 24%;
        background-position: 10% center;
        background-size:  30px 30px;
        background-repeat: no-repeat;
        margin-top:30px;
        @media screen and (min-width: 321px) {
            margin-top:50px;
        }
        a {
            margin: auto;
            margin-left:5px;
            text-align: center;
            color: $white;
            text-decoration: none;
            vertical-align: middle;
        }
        &.icas {
            background-color: white;
            background-image: url({{file_dest}}/icon-128x128.png) ;
            a {
                color: $base-font-colour;
            }
        }
        &.linkedin {
            background-color: #007BB5;
            background-image: url({{file_dest}}/linkedin-icon.svg) ;

        }
        &.twitter {
            background-color: #1DA1F2;
            background-image: url({{file_dest}}/twitter-icon.svg) ;
        }
    }
}