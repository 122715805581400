/* Module: S */
.record_list {
    transition: all 0.5s ease-in-out;
    padding: 10px 10px 80px 10px;
    .untilexpire {
text-align: center;
    }
    .articles_list {
        list-style: none;
        margin: 0;
        padding: 0;
        .nosave {
            text-align: center;
        }
    }
    .articles_list--element {
        clear: both;
        background-color: $white;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
        height: 100px;
        overflow: hidden;
        margin-bottom: 10px;
        a {
            text-decoration: none;
            .content_wrapper {
                position: relative;
                float: left;
                width: calc(100% - 60px);
                height: 100px;
                padding: 8px 0px 8px 10px;
                h3 {
                    color: black;
                    margin: 2px 0px;
                    font-size: pxToRem(18);
                    font-weight: $regular;
                    text-decoration: none;
                }
                .timestamp {
                    margin: 0px;
                    font-size: pxToRem(10);
                    color: $gray-darkest;
                    position: absolute;
                    bottom: 1px;
                    @media screen and (min-width: 321px){
                        bottom: 10px;
                    }
                    left: 10px;
                    line-height: 20px;
                }
            }
            .rmv_wrapper {
                background: url({{file_dest}}/remove.svg) center center no-repeat;
                position: relative;
                padding-bottom: 100px;
                height: 0;
                overflow: hidden;
                margin-bottom: 0;
                width: 60px;
                float: right;
                text-align: center;
                p {
                    position: absolute;
                    bottom: 15px;
                    left: 0;
                    color: $gray-darkest;
                    font-size: pxToRem(10);
                    margin: 0;
                    width: 100%;
                }
            }
        }

    }
}