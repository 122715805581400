//@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600");

/* Roboto light */

@font-face {
    font-family: "Roboto";
    src: url("{{file_dest}}/Roboto-Light.eot");
    src: url("{{file_dest}}/Roboto-Light.eot?#iefix") format('embedded-opentype'),
    url("{{file_dest}}/Roboto-Light.woff2") format('woff2'),
    url("{{file_dest}}/Roboto-Light.woff") format('woff'),
    url("{{file_dest}}/Roboto-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}


/* Roboto regular */

@font-face {
    font-family: "Roboto";
    src: url("{{file_dest}}/Roboto-Regular.eot");
    src: url("{{file_dest}}/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
    url("{{file_dest}}/Roboto-Regular.woff2") format('woff2'),
    url("{{file_dest}}/Roboto-Regular.woff") format('woff'),
    url("{{file_dest}}/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto medium */

@font-face {
    font-family: "Roboto";
    src: url('{{file_dest}}/Roboto-Medium.eot');
    src: url('{{file_dest}}/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/Roboto-Medium.woff2') format('woff2'),
    url('{{file_dest}}/Roboto-Medium.woff') format('woff'),
    url('{{file_dest}}/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Roboto bold */

@font-face {
    font-family: "Roboto";
    src: url('{{file_dest}}/Roboto-Bold.eot');
    src: url('{{file_dest}}/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/Roboto-Bold.woff2') format('woff2'),
    url('{{file_dest}}/Roboto-Bold.woff') format('woff'),
    url('{{file_dest}}/Roboto-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $semibold;
    line-height: 1.3em;
}
