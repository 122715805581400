/* Module: S */
.ios_overlay {
    transition: all 0.5s ease-in-out;
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $gray-button;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.ios_overlay-content {
    margin: 30px;
    background: $white;
    text-align: center;
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    line-height: 20px;
    font-size: pxToRem(12);
    line-height: pxToRem(20);
    @media screen and (min-width: 321px){
        font-size: pxToRem(14);
    }
    .content_top {
        padding: 155px 30px 50px 30px;
        background-image: url({{file_dest}}/icas_logo.gif);
        background-repeat: no-repeat;
        background-position: center 50px;
        background-size: 197px 57px;
        @media screen and (min-width: 321px){
            padding: 155px 50px 50px 50px;
        }
    }
    .content_bottom {
        background-color: $gray-button;
        padding: 18px 10px;
    }
}