/* Module: S */
.articlewrapper, .eventwrapper {
    transition: all 0.5s ease-in-out;
}
.back_arrow_wrapper {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 5;
    .back_arrow {
        margin: 13px 0px 13px 35px;
        background-color: $gray-darkest;
        -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
        display: inline-block;
        padding: 3px 20px;
        height: 30px;
        position: relative;
        cursor: pointer;
        a {
            font-size: pxToRem(14);
            line-height: pxToRem(25);
            color: white;
            text-decoration: none;
        }
        &:before {
            content: '';
            position: absolute;
            transform: rotate(45deg);
            width: 20px;
            height: 20px;
            left: -10px;
            top: 5px;
            z-index: -1;
            -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
            box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.5);
        }
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            left: -15px;
            top: 0px;
            border-style: solid;
            border-width: 15px 15px 15px 0;
            border-color: transparent $gray-darkest transparent transparent;

        }
    }
}


.main_article {
    margin: 56px 15px 15px 15px;
    padding: 15px;
    background-color: $white;
    h1, h2, h3, h4 {
        margin-top: 0px;
        a {
            color: $base-font-colour;
            text-decoration: none;
        }
    }
    p {
        a {
            color: $base-font-colour;
            text-decoration: none;
        }
    }
    iframe {
        max-width: 700px;
        width: 100%;
    }
    .accordion {
        article {
            display: block;
            .content {
                padding-bottom: 10px;
                a {
                    color: #585858;
                    font-weight: 400;
                    text-decoration: underline;
                    transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
            .handle {
                position: relative;
                margin: 0;
                padding: 0.625em 0px 0.625em 0;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid #dcdcdc;
                color: #585858;
                display: flex;
                font-size: 1.25em;
                font-weight: 400;
                justify-content: space-between;
                transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
                .fa-minus {
                    display: none;
                }
                svg {
                    height: 18px;
                    margin-left: 0.25em;
                    min-width: 16px;
                    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
                    width: 16px;
                    fill: #313131;
                    overflow: hidden;
                    vertical-align: middle;
                }
            }
        }
        .open {
            .handle {
                .fa-minus {
                    display: block;
                }
                .fa-plus {
                    display: none;
                }
            }
        }
    }
    .introimage {
        width: 100%;
        height: 0;
        padding-bottom: 146px;
        overflow: hidden;
        margin: 0px;
        margin-bottom: 14px;
        position: relative;
        .image {
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
        }
    }
    .button {
        font-size: pxToRem(10);
        line-height: pxToRem(20);
        padding: 0.6em 1.6em;
        min-width: 156px;
        text-align: center;
        @media screen and (min-width: 321px) {
            font-size: pxToRem(12);
            line-height: pxToRem(16);
            min-width: 178px;
        }
        background-color: $gray-darkest;
        &:focus, &:hover {
            background-color: $gray-darkest;
            color: $white;
            text-decoration: none;
        }
        a {
            color: $white;
        }
    }
    .socialicons {
        margin-bottom: 14px;
        .button {
            font-size: pxToRem(10);
            line-height: pxToRem(20);
            padding: 0.6em 1.6em;
            min-width: 156px;
            text-align: center;
            @media screen and (min-width: 321px) {
                font-size: pxToRem(12);
                line-height: pxToRem(16);
                min-width: 178px;
            }
            background-color: $gray-darkest;
            height: 30px;
            margin: 0;
            &:focus, &:hover {
                background-color: $gray-darkest;
                color: $white;
                text-decoration: none;
            }
            &.active {
                background-color: $yellow;
                color: $base-font-colour;
                position: relative;
                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 10px;
                    left: 10px;
                    top: 8px;
                    transform: rotate(45deg);
                    border-style: solid;
                    border-color: transparent black black transparent;
                    border-width: 1px;
                }
            }
            &.buttonerror {
                background-color: $gray-darkest;
                color: black;
                cursor: default;
                position: relative;
                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 10px;
                    left: 10px;
                    top: 8px;
                    transform: rotate(45deg);
                    border-style: solid;
                    border-color: transparent black transparent transparent;
                    border-width: 1px;
                }
                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 10px;
                    left: 10px;
                    top: 11px;
                    transform: rotate(-45deg);
                    border-style: solid;
                    border-color: transparent black transparent transparent;
                    border-width: 1px;
                }
            }
        }
        ul.share {
            padding: 0;
            margin: 0;
            float: right;
            li {
                display: inline-block;
                a {
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    display: block;
                    &.twitter {
                        background: $gray-darkest url({{file_dest}}/twitter-icon.svg) center center / 13px 13px no-repeat;
                    }
                    &.facebook {
                        background: $gray-darkest url({{file_dest}}/facebook-icon.svg) center center / 13px 13px no-repeat;
                    }
                    &.linkedin {
                        background: $gray-darkest url({{file_dest}}/linkedin-icon.svg) center center / 13px 13px no-repeat;
                    }
                }

            }
        }
    }
}