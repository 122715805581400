/* Module: S */
.error_page {
    transition: all 0.5s ease-in-out;
    margin-top: -68px;
    padding: 10px 10px 80px 10px;
    p {
        text-align: center;
        margin-top:50px;
        a {
            color: $base-font-colour;
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}