/* Base Modding from standard bootstrap */

// Body reset
// --------------------------------------------------

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.7; // 20/14 = 1.428571429; 20/16 = 1.25;
//** Background color for `<body>`.
$body-bg: $white;
//** Global text color on `<body>`.
$text-color: $base-font-colour;

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    //font-size: pxToEm($base-font-pixel);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    background: url({{file_dest}}/home_background.png) center center no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    //font-size: pxToEm($base-font-pixel);
    line-height: $line-height-base;
    color: $text-color;
    font-weight: $regular;
    //background-color: $body-bg;
    -webkit-overflow-scrolling: touch;
    .page-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        position: relative;
    }
    &.gray {
        //background-color: $gray-button;
    }
}

main {
    position: relative;
}