/* Module: S */
.main_navigation {
    z-index:2;
    height: 86px;
    position: fixed;
    bottom: -1px;
    left: 0px;
    right: 0px;
    background: white;
    .navigation-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .navigation-list--element {
        position: relative;
        display: inline-block;
        height: 76px;
        width: 19%;
        line-height: pxToRem(10);
        text-align: center;
        a {
            position: absolute;
            left: 0px;
            top: 0;
            width: 100%;
            color: black;
            font-size: pxToRem(11);
            line-height: pxToRem(10);
            text-decoration: none;
            &.active {
                color: $blue;
                .icon {
                    stroke: $blue;
                    fill: $blue;
                }
            }
            .icon {
                height: 27px;
                fill: black;
                stroke-width: 0.5px;
                margin: 10px 0px;
                width: 100%;
                display: block;
                stroke: black;
            }
        }
    }
}
