/* Module: S */
.sw_message {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: -68px;
    z-index: -1;
    padding: 10px 50px 0px 50px;
    @media screen and (min-width: 321px){
        padding: 10px 70px 0px 70px;
    }
    min-height: 68px;
    max-height: 68px;
    overflow: hidden;
}
.sw_message--offline {
    padding: 15px 0 15px 30px;
    margin: 0;
    font-size: pxToRem(10);
    font-weight: $semibold;
    line-height: pxToRem(14);
    background: url({{file_dest}}/offlineicon.svg) left center no-repeat;
}